const palestineFaqs = [
  {
    id: 0,
    question: 'What relief is MATW providing in Palestine?',
    answer:
      'We are currently providing a range of essential relief in Palestine, including food packs, hot meals, blankets, mattresses, and medical kits. We are committed to address the immediate and critical needs of the people in Palestine.',
  },
  {
    id: 1,
    question: 'How can I donate, and can I use Zakat for my contributions?',
    answer:
      'You can donate through our website and yes, you can use your Zakat donations to support our efforts in Palestine. Zakat is about helping those in need, and providing relief to those in Palestine who have been forcibly displaced is an important cause. At MATW, we ensure that Zakat funds are used for their intended purpose and adhere to Islamic guidelines for Zakat distribution. Your donations will directly benefit those who require assistance in Palestine.',
  },
  {
    id: 2,
    question: 'How do I know my contributions are reaching those in need in Palestine?',
    answer:
      'We have a strict system of accountability and transparency. We maintain detailed records of distribution and regularly conduct audits to ensure that resources are used effectively and efficiently. Our supporters and donors can trust that their contributions are making a significant impact on the ground.',
  },
  {
    id: 3,
    question: 'What assistance is MATW providing in Palestine?',
    answer:
      'We are currently providing a range of essential relief in Palestine, including food packs, hot meals, blankets, mattresses, and medical kits. We are committed to address the immediate and critical needs of the people in Palestine.',
  },
  {
    id: 4,
    question: 'How does MATW adapt to the changing needs in Palestine?',
    answer:
      'We are working to continuously monitor the situation in Palestine and the evolving needs of the Palestine population. We work closely with local partners and humanitarian organisations to stay informed about the changing circumstances. This flexibility allows us to adjust our programmes and assistance as needed, ensuring our support remains relevant and effective.',
  },
  {
    id: 5,
    question: 'Is MATW working exclusively in Palestine?',
    answer: `Our primary focus is on providing critical help and support to the people of Palestine due to the pressing humanitarian needs in the region. The current blockade has created a critical situation, where individuals are struggling to access fundamental necessities such as food, water, and fuel. Furthermore, thousands have been forced into sheltering in schools and public buildings as their homes have been destroyed by military fire. Our decision to concentrate on Palestine is rooted in the acute and ongoing challenges faced by the population in this area. Additionally, we have an established presence in the region, with experienced teams who understand the geography and context of Palestine well, and dedicated facilities set up to efficiently address the needs of the people. This allows us to maximize our impact and respond swiftly to the urgent requirements of the community.`,
  },
  {
    id: 6,
    question: 'How can I help?',
    answer:
      'You can get involved by donating to our emergency Palestine campaign and raising awareness of our efforts. If you are interested in getting directly involved with our assistance handling and distribution efforts, please reach out to our volunteer coordinator for information on volunteer opportunities and how you can contribute your time and skills to this cause.',
  },
  {
    id: 7,
    question: 'How can I stay informed and up to date with the latest information on MATW’s work in Palestine and the impact of my donations?',
    answer:
      'To stay updated on our ongoing efforts and the impact of your contributions, you can visit our website or follow our social media channels. We regularly share news, reports, and stories from the field to keep our supporters informed about the situation and the progress and development of our work in Palestine.',
  },
];
const palestineCampaignFaqs = [
  {
    id: 0,
    question: 'What assistance is MATW providing in Palestine?',
    answer: `We are currently providing a range of essential relief in Palestine, including food packs, hot meals, blankets, mattresses, and medical kits. We are committed to address the immediate and critical needs of the people in Palestine.`,
  },
  {
    id: 1,
    question: 'How does MATW adapt to the changing needs in Palestine?',
    answer: `We are working to continuously monitor the situation in Palestine and the evolving needs of the Palestine population. We work closely with local partners and humanitarian organisations to stay informed about the changing circumstances. This flexibility allows us to adjust our programmes and assistance as needed, ensuring our support remains relevant and effective.`,
  },
  {
    id: 2,
    question: 'What happens when supplies of assistance run out in Palestine?',
    answer: `With the blockade preventing further supplies arriving in Palestine, the prospect of running out of supplies is a very real concern. We are, however, working hard to stockpile and process essential relief materials in preparation for future distribution as there is mounting pressure on the Israeli state to allow a humanitarian corridor. We will be prepared to swiftly distribute assistance and provide critical and timely help when the opportunity arises.`,
  },
  {
    id: 3,
    question: 'How do I know my contributions are reaching those in need in Palestine?',
    answer: `We have a strict system of accountability and transparency. We maintain detailed records of assistance distribution and regularly conduct audits to ensure that resources are used effectively and efficiently. Our supporters and donors can trust that their contributions are making a significant impact on the ground.`,
  },
  {
    id: 4,
    question: 'Is MATW working exclusively in Palestine?',
    answer: `Our primary focus is on providing critical help and support to the people of Palestine due to the pressing humanitarian needs in the region. The current blockade has created a critical situation, where individuals are struggling to access fundamental necessities such as food, water, and fuel. Furthermore, thousands have been forced into sheltering in schools and public buildings as their homes have been destroyed by military fire.
    <br />
    Our decision to concentrate on Palestine is rooted in the acute and ongoing challenges faced by the population in this area. Additionally, we have an established presence in the region, with experienced teams who understand the geography and context of Palestine well, and dedicated facilities set up to efficiently address the needs of the people. This allows us to maximize our impact and respond swiftly to the urgent requirements of the community.`,
  },
  {
    id: 5,
    question: 'How can I help?',
    answer: `You can get involved by donating to our emergency Palestine campaign and raising awareness of our efforts.
    If you are interested in getting directly involved with our assistance handling and distribution efforts, please reach out to our volunteer coordinator for information on volunteer opportunities and how you can contribute your time and skills to this cause.`,
  },
  {
    id: 6,
    question: `How can I stay informed and up to date with the latest information of MATW’s work in Palestine and the impact of my donations?`,
    answer: `To stay updated on our ongoing efforts and the impact of your contributions, you can visit our website or follow our social media channels. We regularly share news, reports, and stories from the field to keep our supporters informed about the situation and the progress and development of our work in Palestine.`,
  },
];
const palestineFaqsArabic = [
  {
    id: 0,
    question: 'كيف يمكنني التحقق من وصول تبرعاتي إلى المحتاجين في فلسطين؟',
    answer:
      'لدينا نظام صارم للمساءلة والشفافية. نحن نحتفظ بسجلات مفصلة لتوزيع المساعدات ونجري عمليات تدقيق منتظمة لضمان استخدام الموارد بفعالية وكفاءة. يمكن لمؤيدينا والجهات المانحة لنا أن يثقوا في أن مساهماتهم تحدث تأثيرًا كبيرًا على أرض الواقع.',
  },
  {
    id: 1,
    question: 'ﻛﻴﻒ ﻳﻤﻜﻨﻨﻲ اﻟﺘﺒﺮع وﻫﻞ ﻳﻤﻜﻨﻨﻲ اﺳﺘﺨﺪام اﻟﺰﻛﺎة ﻓﻲ ﻣﺴﺎﻫﻤﺎﺗﻲ؟',
    answer:
      'ﻳﻤﻜﻨﻜﻢ اﻟﺘﺒﺮع ﻋﺒﺮ ﻣﻮﻗﻌﻨﺎ اﻹﻟﻜﺘﺮوﻧﻲ وﻧﻌﻢ، ﻳﻤﻜﻨﻜﻢ اﺳﺘﺨﺪام ﺗﺒﺮﻋﺎﺗﻜﻢ اﻟﺰﻛﻮﻳﺔ ﻟﺪ ًﻋﻢ ﺟﻬﻮدﻧﺎ ﻓﻲ ﻓﻠﺴﻄﻴﻦ. ﺗﻬﺪف اﻟﺰﻛﺎة إﱃ ﻣﺴﺎﻋﺪة اﻟﻤﺤﺘﺎﺟﻴﻦ، ﻛﻤﺎ أن ﺗﻘﺪﻳﻢ اﻟﻤﺴﺎﻋﺪة ﻷوﻟﺌﻚ اﻟﺬﻳﻦ ﻫﺠﺮوا ﻗﺴﺮا ﻓﻲ ﻓﻠﺴﻄﻴﻦ ﻫﻮ ﻗﻀﻴﺔ ﻣﻬﻤﺔ. ﻓﻲ MATW، ﻧﻀﻤﻦ اﺳﺘﺨﺪام أﻣﻮال اﻟﺰﻛﺎة ﻟﻠﻐﺮض اﻟﻤﻘﺼﻮد ﻣﻨﻬﺎ واﻻﻟﺘﺰام ﺑﺎﻟﻤﺒﺎدئ اﻟﺘﻮﺟﻴﻬﻴﺔ اﻹﺳﻼﻣﻴﺔ ﻟﺘﻮزﻳﻊ اﻟﺰﻛﺎة. ﺗﺒﺮﻋﺎﺗﻜﻢ ﺳﺘﻔﻴﺪ ﺑﺸﻜﻞ ﻣﺒﺎﺷﺮ أوﻟﺌﻚ اﻟﺬﻳﻦ ﻳﺤﺘﺎﺟﻮن إﱃ اﻟﻤﺴﺎﻋﺪة ﻓﻲ ﻓﻠﺴﻄﻴﻦ.',
  },
  {
    id: 2,
    question: 'ﻣﺎ ﻫﻲ اﻟﻤﺴﺎﻋﺪات اﻟﺘﻲ ﺗﻘﺪﻣﻬﺎ MATW ﻓﻠﺴﻄﻴﻦ؟',
    answer: 'واﻟﻮﺟﺒﺎت اﻟﺴﺎﺧﻨﺔ واﻟﺒﻄﺎﻧﻴﺎت واﻟﻤﻔﺎرش واﻷﻃﻘﻢ اﻟﻄﺒﻴﺔ. وﻧﺤﻦ ﻣﻠﺘﺰﻣﻮن ﺑﺘﻠﺒﻴﺔ اﻻﺣﺘﻴﺎﺟﺎت اﻟﻔﻮرﻳﺔ واﻟﺤﺮﺟﺔ ﻟﻠﺸﻌﺐ ﻓﻲ ﻓﻠﺴﻄﻴﻦ.',
  },
  {
    id: 3,
    question: 'ما هي المساعدة التي يقدمها MATW في فلسطين؟',
    answer:
      'نحن حاليًا نقدم مجموعة من المساعدات الأساسية في فلسطين، بما في ذلك حزم الطعام، ووجبات ساخنة، وبطانيات، وفرشات، وحقائب طبية. نحن ملتزمون بمعالجة الاحتياجات الفورية والحرجة للأفراد في فلسطين.',
  },
  {
    id: 4,
    question: 'ﻛﻴﻒ ﻳﺘﻜﻴﻒ MATW ﻣﻊ اﻻﺣﺘﻴﺎﺟﺎت اﻟﻤﺘﻐﻴﺮة ﻓﻲ ﻓﻠﺴﻄﻴﻦ؟',
    answer:
      'نحن نعمل على مراقبة الوضع في فلسطين بشكل مستمر ومتابعة تطور احتياجات السكان الفلسطينيين. نتعاون عن كثب مع الشركاء المحليين والمنظمات الإنسانية للبقاء على اطلاع دائم بالتغيرات الحاصلة. هذه المرونة تتيح لنا تعديل برامجنا ومساعدتنا حسب الحاجة، مضمونين بأن دعمنا يظل ذا صلة وفعّال.',
  },
  {
    id: 5,
    question: 'ﻫﻞ ﺗﻌﻤﻞ MATW ﺣﺼﺮﻳًﺎ ﻓﻲ ﻓﻠﺴﻄﻴﻦ؟',
    answer: `ﻫﺪﻓﻨﺎ وﺗﺮﻛﻴﺰﻧﺎ اﻷﺳﺎﺳﻲ ﻋﲆ ﺗﻘﺪﻳﻢ اﻟﻤﺴﺎﻋﺪات واﻟﺪﻋﻢ اﻟﻀﺮوري ﻟﺸﻌﺐ ﻓﻠﺴﻄﻴﻦ ﺑﺴﺒﺐ اﻻﺣﺘﻴﺎﺟﺎت اﻹﻧﺴﺎﻧﻴﺔ اﻟﻤﻠﺤﺔ ﻓﻲ اﻟﻤﻨﻄﻘﺔ. ﻟﻘﺪ ﺧﻠﻖ اﻟﺤﺼﺎر اﻟﺤﺎﻟﻲ وﺿﻌﺎً ﺣﺮﺟﺎً، ﺣﻴﺚ ﻳﻜﺎﻓﺢ اﻷﻓﺮاد ﻣﻦ أﺟﻞ اﻟﻮﺻﻮل إﱃ اﻟﻀﺮورﻳﺎت
    اﻷﺳﺎﺳﻴﺔ ﻣﺜﻞ اﻟﻐﺬاء واﻟﻤﺎء واﻟﻮﻗﻮد. ﻋﻼوة ﻋﲆ ذﻟﻚ، اﺿﻄﺮ اﻵﻻف إﱃ اﻻﺣﺘﻤﺎء ﻓﻲ اﻟﻤﺪارس واﻟﻤﺒﺎﻧﻲ اﻟﻌﺎﻣﺔ ﺑﻌﺪ أن
    دﻣﺮت اﻟﻨﻴﺮان اﻟﻌﺴﻜﺮﻳﺔ ﻣﻨﺎزﻟﻬﻢ.إن ﻗﺮارﻧﺎ ﺑﺎﻟﺘﺮﻛﻴﺰ ﻋﲆ ﻓﻠﺴﻄﻴﻦ ﻣﺘﺠﺬر ﻓﻲ اﻟﺘﺤﺪﻳﺎت اﻟﺤﺎدة واﻟﻤﺴﺘﻤﺮة اﻟﺘﻲ ﻳﻮاﺟﻬﻬﺎ اﻟﺴﻜﺎن ﻓﻲ ﻫﺬه اﻟﻤﻨﻄﻘﺔ.
    ﺑﺎﻹﺿﺎﻓﺔ إﱃ ذﻟﻚ، ﻟﺪﻳﻨﺎ ﺣﻀﻮر راﺳﺦ ﻓﻲ اﻟﻤﻨﻄﻘﺔ، ﻣﻊ ﻓﺮق ﻣﻦ ذوي اﻟﺨﺒﺮة اﻟﺬﻳﻦ ﻳﻔﻬﻤﻮن ﺟﻐﺮاﻓﻴﺔ وﺳﻴﺎق ﻓﻠﺴﻄﻴﻦ
    ﺟﻴﺪا، وﻣﺮاﻓﻖ ﻣﺨﺼﺼﺔ ﺗﻢ إﻋﺪادﻫﺎ ﻟﺘﻠﺒﻴﺔ اﺣﺘﻴﺎﺟﺎت اﻟﻨﺎس ﺑﻜﻔﺎءة. وﻫﺬا ﻳﺘﻴﺢ ﻟﻨﺎ ﺗﻌﻈﻴﻢ ﺗﺄﺛﻴﺮﻧﺎ واﻻﺳﺘﺠﺎﺑﺔ ﺑﺴﺮﻋﺔ
    ﻟﻠﻤﺘﻄﻠﺒﺎت اﻟﻌﺎﺟﻠﺔ ﻟﻠﻤﺠﺘﻤﻊ.`,
  },
  {
    id: 6,
    question: 'ﻛﻴﻒ ﻳﻤﻜﻨﻨﺎ ﻣﺴﺎﻋﺪﺗﻚ؟',
    answer: `ﻳﻤﻜﻨﻚ اﻟﻤﺸﺎرﻛﺔ ﻣﻦ ﺧﻼل اﻟﺘﺒﺮع ﻟﺤﻤﻠﺔ ﻓﻠﺴﻄﻴﻦ اﻟﻄﺎرﺋﺔ ورﻓﻊ ﻣﺴﺘﻮى اﻟﻮﻋﻲ ﺑﺠﻬﻮدﻧﺎ. إذا ﻛﻨﺖ ﻣﻬﺘﻤﺎ ﺑﺎﻟﻤﺸﺎرﻛﺔ
    ﺑﺸﻜﻞ ﻣﺒﺎﺷﺮ ﻓﻲ ﺟﻬﻮد ﻣﻌﺎﻟﺠﺔ اﻟﻤﺴﺎﻋﺪات وﺗﻮزﻳﻌﻬﺎ، ﻓﻴﺮﺟﻰ اﻟﺘﻮاﺻﻞ ﻣﻊ ﻣﻨﺴﻖ اﻟﻤﺘﻄﻮﻋﻴﻦ ﻟﺪﻳﻨﺎ ﻟﻠﺤﺼﻮل ﻋﲆ
    ﻣﻌﻠﻮﻣﺎت ﺣﻮل ﻓﺮص اﻟﺘﻄﻮع وﻛﻴﻒ ﻳﻤﻜﻨﻚ اﻟﻤﺴﺎﻫﻤﺔ ﺑﻮﻗﺘﻚ وﻣﻬﺎراﺗﻚ ﻓﻲ ﻫﺬه اﻟﻘﻀﻴﺔ.`,
  },
  {
    id: 7,
    question: 'ﻛﻴﻒ ﻳﻤﻜﻨﻨﻲ اﻟﺒﻘﺎء ﻋﲆ اﻃﻼع ﺑﺄﺣﺪث اﻟﻤﻌﻠﻮﻣﺎت ﺣﻮل ﻋﻤﻞ MATW ﻓﻲ ﻓﻠﺴﻄﻴﻦ وﺗﺄﺛﻴﺮ ﺗﺒﺮﻋﺎﺗﻲ؟',
    answer:
      'ﻟﻠﺒﻘﺎء ﻋﲆ اﻃﻼع داﺋﻢ ﺑﺠﻬﻮدﻧﺎ اﻟﻤﺴﺘﻤﺮة وﺗﺄﺛﻴﺮ ﻣﺴﺎﻫﻤﺎﺗﻚ، ﻳﻤﻜﻨﻚ زﻳﺎرة ﻣﻮﻗﻌﻨﺎ اﻹﻟﻜﺘﺮوﻧﻲ أو ﻣﺘﺎﺑﻌﺔ ﻗﻨﻮات اﻟﺘﻮاﺻﻞ اﻻﺟﺘﻤﺎﻋﻲ اﻟﺨﺎﺻﺔ ﺑﻨﺎ. ﻧﺤﻦ ﻧﺸﺎرك ﺑﺎﻧﺘﻈﺎم اﻷﺧﺒﺎر واﻟﺘﻘﺎرﻳﺮ واﻟﻘﺼﺺ ﻣﻦ اﻟﻤﻴﺪان ﻹﺑﻘﺎء داﻋﻤﻴﻨﺎ ﻋﲆ اﻃﻼع ﻋﲆ اﻟﻮﺿﻊ واﻟﺘﻘﺪم واﻟﺘﻄﻮر ﻓﻲ ﻋﻤﻠﻨﺎ ﻓﻲ ﻓﻠﺴﻄﻴﻦ.',
  },
];
const newPalestineFaqsArabic = [
  {
    id: 0,
    question: 'ما هي المساعدات التي تقدمها MATW في فلسطين؟',
    answer: `نحن نقدم حاليا مجموعة من المساعدات الأساسية في فلسطين، بما في ذلك الطرود الغذائية والوجبات الساخنة والبطانيات والفرش والمستلزمات الطبية. نحن ملتزمون بتلبية الاحتياجات الفورية والماسة للشعب الفلسطيني.`
  },
  {
    id: 1,
    question: 'كيف يمكنني التبرع ، وهل يمكنني استخدام الزكاة لمساهماتي؟',
    answer: `يمكنك التبرع من خلال موقعنا الإلكتروني ونعم ، يمكنك استخدام تبرعاتك الزكوية لدعم جهودنا في فلسطين. الزكاة هي مساعدة المحتاجين، وتقديم المساعدات لأولئك الذين تم تهجيرهم قسرا في فلسطين هو قضية مهمة. في MATW ، نضمن استخدام أموال الزكاة للغرض المقصود منها والالتزام بالمبادئ التوجيهية الإسلامية لتوزيع الزكاة. تبرعاتكم ستفيد بشكل مباشر أولئك الذين يحتاجون إلى المساعدة في فلسطين.`
  },
  {
    id: 2,
    question: 'كيف أعرف أن مساهماتي تصل إلى المحتاجين في فلسطين؟',
    answer: 'لدينا نظام صارم للمساءلة والشفافية. نحتفظ بسجلات مفصلة لتوزيع المساعدات ونجري عمليات تدقيق منتظمة لضمان استخدام الموارد بفعالية وكفاءة. يمكن لداعمينا ومانحينا أن يثقوا في أن مساهماتهم تحدث تأثيرا كبيرا على أرض الواقع',
  },
  {
    id: 3,
    question: 'ما هي المساعدات التي تقدمها MATW في فلسطين؟',
    answer: 'نحن نقدم حاليا مجموعة من مواد الإغاثة الأساسية في فلسطين، بما في ذلك الطرود الغذائية والوجبات الساخنة والبطانيات والفرش والمستلزمات الطبية. نحن ملتزمون بتلبية الاحتياجات الفورية والماسة للشعب الفلسطيني',
  },
  {
    id: 4,
    question: 'كيف تتكيف MATW مع الاحتياجات المتغيرة في فلسطين؟',
    answer: 'نحن نعمل على الرصد المستمر للوضع في فلسطين والاحتياجات المتطورة لسكان فلسطين. نحن نعمل عن كثب مع الشركاء المحليين والمنظمات الإنسانية للبقاء على اطلاع بالظروف المتغيرة. وتسمح لنا هذه المرونة بتعديل برامجنا ومساعداتنا حسب الحاجة، مما يضمن بقاء دعمنا مناسبا وفعالا',
  },
  {
    id: 5,
    question: 'هل تعمل MATW حصريا في فلسطين؟',
    answer: `ينصب تركيزنا الأساسي على تقديم المساعدة والدعم الضروريين لشعب فلسطين بسبب الاحتياجات الإنسانية الملحة في المنطقة. لقد خلق الحصار الحالي وضعا حرجا، حيث يكافح الأفراد للوصول إلى الضروريات الأساسية مثل الغذاء والماء والوقود. وعلاوة على ذلك، أجبر الآلاف على اللجوء إلى المدارس والمباني العامة حيث دمرت منازلهم بنيران الجيش. إن قرارنا بالتركيز على فلسطين متجذر في التحديات الحادة والمستمرة التي يواجهها السكان في هذه المنطقة. بالإضافة إلى ذلك ، لدينا وجود راسخ في المنطقة ، مع فرق من ذوي الخبرة الذين يفهمون جغرافية وسياق فلسطين جيدا ، ومرافق مخصصة تم إنشاؤها لتلبية احتياجات الناس بكفاءة. وهذا يسمح لنا بتعظيم تأثيرنا والاستجابة بسرعة للمتطلبات الملحة للمجتمع`,
  },
  {
    id: 6,
    question: 'كيف يمكنني المساعدة؟',
    answer: `يمكنك المشاركة من خلال التبرع لحملتنا الطارئة في فلسطين وزيادة الوعي بجهودنا. إذا كنت مهتما بالمشاركة بشكل مباشر في جهود التعامل مع المساعدة وتوزيعها ، فيرجى التواصل مع منسق المتطوعين لدينا للحصول على معلومات حول فرص التطوع وكيف يمكنك المساهمة بوقتك ومهاراتك في هذه القضية`,
  },
  {
    id: 7,
    question: 'كيف يمكنني البقاء على اطلاع وتحديث بأحدث المعلومات حول عمل MATW في فلسطين وتأثير تبرعاتي؟',
    answer: 'للبقاء على اطلاع دائم بجهودنا المستمرة وتأثير مساهماتك ، يمكنك زيارة موقعنا على الإنترنت أو متابعة قنوات التواصل الاجتماعي الخاصة بنا. نحن نشارك بانتظام الأخبار والتقارير والقصص من الميدان لإبقاء مؤيدينا على اطلاع على الوضع والتقدم والتطور في عملنا في فلسطين',
  },
];
const palestineFaqsFrench = [
  {
    id: 0,
    question: 'Quelle aide MATW apporte-t-elle en Palestine ?',
    answer: `Nous fournissons actuellement une gamme d’aide essentielle en Palestine, notamment des colis alimentaires, des repas chauds, des couvertures, des matelas et des kits médicaux. Nous sommes déterminés à répondre aux besoins immédiats et critiques du peuple palestinien.`
  },
  {
    id: 1,
    question: 'Comment puis-je faire un don et puis-je utiliser la Zakat pour mes contributions ?',
    answer: `Vous pouvez faire un don via notre site Web et oui, vous pouvez utiliser vos dons de Zakat pour soutenir nos efforts en Palestine. La Zakat a pour but d’aider ceux qui sont dans le besoin, et fournir de l’aide à ceux qui ont été déplacés de force en Palestine est une cause importante. Chez MATW, nous veillons à ce que les fonds de la Zakat soient utilisés aux fins prévues et respectent les directives islamiques pour la distribution de la Zakat. Vos dons bénéficieront directement à ceux qui ont besoin d’aide en Palestine.`
  },
  {
    id: 2,
    question: 'Comment puis-je savoir si mes contributions parviennent à ceux qui en ont besoin en Palestine ?',
    answer: 'Nous avons un système strict de reddition de comptes et de transparence. Nous tenons des registres détaillés de la distribution de l’aide et effectuons régulièrement des audits pour nous assurer que les ressources sont utilisées de manière efficace et efficiente. Nos sympathisants et donateurs peuvent être sûrs que leurs contributions ont un impact significatif sur le terrain.',
  },
  {
    id: 3,
    question: 'Quelle aide le MATW apporte-t-il en Palestine ?',
    answer: 'Nous fournissons actuellement une gamme de secours essentiels en Palestine, notamment des colis alimentaires, des repas chauds, des couvertures, des matelas et des kits médicaux. Nous sommes déterminés à répondre aux besoins immédiats et critiques du peuple palestinien.',
  },
  {
    id: 4,
    question: 'Comment MATW s’adapte-t-il à l’évolution des besoins en Palestine ?',
    answer: 'Nous nous efforçons de suivre en permanence la situation en Palestine et l’évolution des besoins de la population palestinienne. Nous travaillons en étroite collaboration avec des partenaires locaux et des organisations humanitaires pour rester informés de l’évolution de la situation. Cette flexibilité nous permet d’ajuster nos programmes et notre assistance en fonction des besoins, en veillant à ce que notre soutien reste pertinent et efficace.',
  },
  {
    id: 5,
    question: 'Est-ce que MATW travaille exclusivement en Palestine ?',
    answer: `Notre objectif principal est de fournir une aide et un soutien essentiels au peuple de Palestine en raison des besoins humanitaires urgents dans la région. Le blocus actuel a créé une situation critique, où les individus ont du mal à accéder aux produits de première nécessité tels que la nourriture, l’eau et le carburant. En outre, des milliers de personnes ont été contraintes de se réfugier dans des écoles et des bâtiments publics, leurs maisons ayant été détruites par des tirs militaires. Notre décision de nous concentrer sur la Palestine est enracinée dans les défis aigus et permanents auxquels est confrontée la population de cette région. De plus, nous avons une présence établie dans la région, avec des équipes expérimentées qui comprennent bien la géographie et le contexte de la Palestine, et des installations dédiées mises en place pour répondre efficacement aux besoins de la population. Cela nous permet de maximiser notre impact et de répondre rapidement aux besoins urgents de la communauté.`,
  },
  {
    id: 6,
    question: 'Comment puis-je aider ?',
    answer: `Vous pouvez vous impliquer en faisant un don à notre campagne d’urgence pour la Palestine et en sensibilisant le public à nos efforts. Si vous souhaitez vous impliquer directement dans nos efforts de gestion et de distribution de l’aide, veuillez communiquer avec notre coordonnatrice des bénévoles pour obtenir des renseignements sur les possibilités de bénévolat et sur la façon dont vous pouvez donner de votre temps et de vos compétences à cette cause.`,
  },
  {
    id: 7,
    question: 'Comment puis-je rester informé et à jour avec les dernières informations sur le travail de MATW en Palestine et l’impact de mes dons ?',
    answer: 'Pour vous tenir au courant de nos efforts continus et de l’impact de vos contributions, vous pouvez visiter notre site Web ou suivre nos canaux de médias sociaux. Nous partageons régulièrement des nouvelles, des rapports et des histoires sur le terrain pour tenir nos sympathisants informés de la situation et de l’avancement et du développement de notre travail en Palestine.',
  },
];


const bangladeshEmergency = [
  {
    id: 0,
    question: 'Does MATW work in Bangladesh?',
    answer: `Yes. With operations in the North and South, MATW Project provides orphan support, clean water solutions and food relief in Bangladesh. We also set up makeshift medical clinics to help treat those who are most vulnerable.`,
  },
  {
    id: 1,
    question: 'What is MATW delivering in response to the floods?',
    answer: 'Our team has mobilized and is delivering food relief including family food packs, 40KG rice bags, month long supply of baby milk per family, emergency education essentials, medical treatment and more. ',
  }
];
const palestineRefugeeFaqs = [
  {
    id: 0,
    question: 'What is the difference between the sponsorship program and settlement support?',
    answer: `Both the Palestinian Refugee Sponsorship and Settlement Support helps Palestinian refugee families who have just relocated to Egypt start fresh. The sponsorship provides some financial independence after they’ve settled and helps to cover essential needs such as food, medical expenses, rent, and education costs whenever feasible. This will help alleviate financial burdens and empower families to meet their basic requirements with dignity.
    The Settlement Support Pack provides crucial household items and furnishings to get them started including appliances such as a Washing Machine, Cooker, Fridge, Kettle, Water Heater, Water Filter, Stand Fan, and Heater and more.`,
  },
  {
    id: 1,
    question: 'How are the refugee families identified?',
    answer: `MATW has built long standing relationships with local authorities and organisations who help in registering families who have newly arrived. MATW then commences our needs analysis process by meeting the families and conducting interviews to determine families meet the requirements for support.`,
  },
  {
    id: 2,
    question: 'How long can I sponsor a family for?',
    answer: `Every sponsorship amount is a one off donation that covers an entire month. You can register to have this amount as recurring by contacting our team or by regularly making a payment to this program.`,
  },
  {
    id: 3,
    question: 'What relief is MATW providing in Palestine?',
    answer:
      'We are currently providing a range of essential relief in Palestine, including food packs, hot meals, blankets, mattresses, and medical kits. We are committed to address the immediate and critical needs of the people in Palestine.',
  },
  {
    id: 4,
    question: 'How can I donate, and can I use Zakat for my contributions?',
    answer:
      'You can donate through our website and yes, you can use your Zakat donations to support our efforts in Palestine. Zakat is about helping those in need, and providing relief to those in Palestine who have been forcibly displaced is an important cause. At MATW, we ensure that Zakat funds are used for their intended purpose and adhere to Islamic guidelines for Zakat distribution. Your donations will directly benefit those who require assistance in Palestine.',
  },
  {
    id: 5,
    question: 'How do I know my contributions are reaching those in need in Palestine?',
    answer:
      'We have a strict system of accountability and transparency. We maintain detailed records of relief distribution and regularly conduct audits to ensure that resources are used effectively and efficiently. Our supporters and donors can trust that their contributions are making a significant impact on the ground.',
  },
  {
    id: 6,
    question: 'What assistance is MATW providing in Palestine?',
    answer:
      'We are currently providing a range of essential relief in Palestine, including food packs, hot meals, blankets, mattresses, and medical kits. We are committed to address the immediate and critical needs of the people in Palestine.',
  },
  {
    id: 7,
    question: 'Is MATW working exclusively in Palestine?',
    answer: `Our primary focus is on providing critical help and support to the people of Palestine due to the pressing humanitarian needs in the region. The current blockade has created a critical situation, where individuals are struggling to access fundamental necessities such as food, water, and fuel. Furthermore, thousands have been forced into sheltering in schools and public buildings as their homes have been destroyed by military fire. Our decision to concentrate on Palestine is rooted in the acute and ongoing challenges faced by the population in this area. Additionally, we have an established presence in the region, with experienced teams who understand the geography and context of Palestine well, and dedicated facilities set up to efficiently address the needs of the people. This allows us to maximize our impact and respond swiftly to the urgent requirements of the community.`,
  },
  {
    id: 8,
    question: 'How can I help?',
    answer:
      'You can get involved by donating to our emergency Palestine campaign and raising awareness of our efforts. If you are interested in getting directly involved with our assistance handling and distribution efforts, please reach out to our volunteer coordinator for information on volunteer opportunities and how you can contribute your time and skills to this cause.',
  },
  {
    id: 9,
    question: 'How can I stay informed and up to date with the latest information on MATW’s work in Palestine and the impact of my donations?',
    answer:
      'To stay updated on our ongoing efforts and the impact of your contributions, you can visit our website or follow our social media channels. We regularly share news, reports, and stories from the field to keep our supporters informed about the situation and the progress and development of our work in Palestine.',
  },
];
const palestineRefugeeFaqsFrench = [
  {
    id: 0,
    question: 'Quelle est la différence entre le programme de parrainage et le soutien à l’établissement ?',
    answer: `Le parrainage de réfugiés palestiniens et l’aide à l’établissement aident les familles de réfugiés palestiniens qui viennent de s’installer en Égypte à prendre un nouveau départ. Le parrainage leur offre une certaine indépendance financière après leur installation et aide à couvrir les besoins essentiels tels que la nourriture, les frais médicaux, le loyer et les frais d’éducation dans la mesure du possible. Cela contribuera à alléger le fardeau financier et à donner aux familles les moyens de répondre à leurs besoins fondamentaux dans la dignité. Le pack de soutien à l’établissement fournit des articles ménagers et des meubles essentiels pour les aider à démarrer, y compris des appareils tels qu’une machine à laver, une cuisinière, un réfrigérateur, une bouilloire, un chauffe-eau, un filtre à eau, un ventilateur sur pied, un radiateur et plus encore.`,
  },
  {
    id: 1,
    question: 'Comment les familles de réfugiés sont-elles identifiées ?',
    answer: `MATW a établi des relations de longue date avec les autorités locales et les organisations qui aident à enregistrer les familles nouvellement arrivées. MATW commence ensuite son processus d’analyse des besoins en rencontrant les familles et en menant des entrevues pour déterminer si les familles répondent aux exigences en matière de soutien.`,
  },
  {
    id: 2,
    question: 'Pendant combien de temps puis-je parrainer une famille ?',
    answer: `Chaque montant de parrainage est un don unique qui couvre un mois entier. Vous pouvez vous inscrire pour avoir ce montant comme récurrent en contactant notre équipe ou en effectuant régulièrement un paiement à ce programme.`,
  },
  {
    id: 3,
    question: 'Quelle aide MATW apporte-t-elle en Palestine ?',
    answer: `Nous fournissons actuellement une gamme d’aide essentielle en Palestine, notamment des colis alimentaires, des repas chauds, des couvertures, des matelas et des kits médicaux. Nous sommes déterminés à répondre aux besoins immédiats et critiques du peuple palestinien.`,
  },
  {
    id: 4,
    question: 'Comment puis-je faire un don et puis-je utiliser la Zakat pour mes contributions ?',
    answer: `Vous pouvez faire un don via notre site Web et oui, vous pouvez utiliser vos dons de Zakat pour soutenir nos efforts en Palestine. La Zakat a pour but d’aider ceux qui sont dans le besoin, et fournir de l’aide à ceux qui ont été déplacés de force en Palestine est une cause importante. Chez MATW, nous veillons à ce que les fonds de la Zakat soient utilisés aux fins prévues et respectent les directives islamiques pour la distribution de la Zakat. Vos dons bénéficieront directement à ceux qui ont besoin d’aide en Palestine.`,
  },
  {
    id: 5,
    question: 'Comment puis-je savoir que mes contributions parviennent à ceux qui sont dans le besoin en Palestine ?',
    answer: `Nous avons un système strict de reddition de comptes et de transparence. Nous tenons des registres détaillés de la distribution de l’aide et effectuons régulièrement des audits pour nous assurer que les ressources sont utilisées de manière efficace et efficiente. Nos sympathisants et donateurs peuvent être sûrs que leurs contributions ont un impact significatif sur le terrain.`,
  },
  {
    id: 6,
    question: 'Quelle aide le MATW apporte-t-il en Palestine ?',
    answer:
      'Nous fournissons actuellement une gamme de secours essentiels en Palestine, notamment des colis alimentaires, des repas chauds, des couvertures, des matelas et des kits médicaux. Nous sommes déterminés à répondre aux besoins immédiats et critiques du peuple palestinien.',
  },
  {
    id: 7,
    question: 'Est-ce que MATW travaille exclusivement en Palestine ?',
    answer: `Notre objectif principal est de fournir une aide et un soutien essentiels au peuple de Palestine en raison des besoins humanitaires urgents dans la région. Le blocus actuel a créé une situation critique, où les individus ont du mal à accéder aux produits de première nécessité tels que la nourriture, l’eau et le carburant. En outre, des milliers de personnes ont été contraintes de se réfugier dans des écoles et des bâtiments publics, leurs maisons ayant été détruites par des tirs militaires. Notre décision de nous concentrer sur la Palestine est enracinée dans les défis aigus et permanents auxquels est confrontée la population de cette région. De plus, nous avons une présence établie dans la région, avec des équipes expérimentées qui comprennent bien la géographie et le contexte de la Palestine, et des installations dédiées mises en place pour répondre efficacement aux besoins de la population. Cela nous permet de maximiser notre impact et de répondre rapidement aux besoins urgents de la communauté.`,
  },
  {
    id: 8,
    question: 'Comment puis-je aider ?',
    answer:
      'Vous pouvez vous impliquer en faisant un don à notre campagne d’urgence pour la Palestine et en sensibilisant le public à nos efforts. Si vous souhaitez vous impliquer directement dans nos efforts de gestion et de distribution de l’aide, veuillez communiquer avec notre coordonnatrice des bénévoles pour obtenir des renseignements sur les possibilités de bénévolat et sur la façon dont vous pouvez donner de votre temps et de vos compétences à cette cause.',
  },
  {
    id: 9,
    question: 'Comment puis-je rester informé et à jour avec les dernières informations sur le travail de MATW en Palestine et l’impact de mes dons ?',
    answer:
      'Pour vous tenir au courant de nos efforts continus et de l’impact de vos contributions, vous pouvez visiter notre site Web ou suivre nos canaux de médias sociaux. Nous partageons régulièrement des nouvelles, des rapports et des histoires sur le terrain pour tenir nos sympathisants informés de la situation et des progrès et du développement de notre travail en Palestine.',
  },
];
const palestineRefugeeFaqsArabic = [
  {
    id: 0,
    question: 'ما هو الفرق بين برنامج الرعاية ودعم التسوية؟',
    answer: `يساعد كل من برنامج رعاية اللاجئين الفلسطينيين ودعم التوطين عائلات اللاجئين الفلسطينيين الذين انتقلوا للتو إلى مصر على البدء من جديد. توفر الرعاية بعض الاستقلال المالي بعد أن يستقروا وتساعد على تغطية الاحتياجات الأساسية مثل الطعام والنفقات الطبية والإيجار وتكاليف التعليم كلما كان ذلك ممكنا. وسيساعد ذلك على تخفيف الأعباء المالية وتمكين الأسر من تلبية متطلباتها الأساسية بكرامة. توفر حزمة دعم التسوية الأدوات المنزلية والمفروشات الأساسية لبدء تشغيلها بما في ذلك الأجهزة مثل الغسالة ، والطباخ ، والثلاجة ، والغلاية ، وسخان المياه ، وفلتر المياه ، ومروحة الوقوف ، والسخان والمزيد ".`,
  },
  {
    id: 1,
    question: 'كيف يتم التعرف على عائلات اللاجئين؟',
    answer: `أقامت MATW علاقات طويلة الأمد مع السلطات والمنظمات المحلية التي تساعد في تسجيل العائلات التي وصلت حديثا. ثم تبدأ MATW عملية تحليل الاحتياجات من خلال مقابلة العائلات وإجراء المقابلات لتحديد أن العائلات تلبي متطلبات الدعم ".`,
  },
  {
    id: 2,
    question: 'كم من الوقت يمكنني كفالة عائلة؟',
    answer: `كل مبلغ رعاية هو تبرع لمرة واحدة يغطي شهرا كاملا. يمكنك التسجيل للحصول على هذا المبلغ على أنه متكرر عن طريق الاتصال بفريقنا أو عن طريق الدفع بانتظام لهذا البرنامج ".`,
  },
  {
    id: 3,
    question: 'ما هي المساعدات التي تقدمها MATW في فلسطين؟',
    answer: `نحن نقدم حاليا مجموعة من المساعدات الأساسية في فلسطين، بما في ذلك الطرود الغذائية والوجبات الساخنة والبطانيات والفرش والمستلزمات الطبية. نحن ملتزمون بتلبية الاحتياجات الفورية والماسة للشعب الفلسطيني ".`,
  },
  {
    id: 4,
    question: 'كيف يمكنني التبرع ، وهل يمكنني استخدام الزكاة لمساهماتي؟',
    answer: `يمكنك التبرع من خلال موقعنا الإلكتروني ونعم ، يمكنك استخدام تبرعاتك الزكوية لدعم جهودنا في فلسطين. الزكاة هي مساعدة المحتاجين، وتقديم المساعدات لأولئك الذين تم تهجيرهم قسرا في فلسطين هو قضية مهمة. في MATW ، نضمن استخدام أموال الزكاة للغرض المقصود منها والالتزام بالمبادئ التوجيهية الإسلامية لتوزيع الزكاة. تبرعاتكم ستفيد بشكل مباشر أولئك الذين يحتاجون إلى المساعدة في فلسطين".`,
  },
  {
    id: 5,
    question: 'كيف أعرف أن مساهماتي تصل إلى المحتاجين في فلسطين؟',
    answer: `لدينا نظام صارم للمساءلة والشفافية. نحتفظ بسجلات مفصلة لتوزيع المساعدات ونجري عمليات تدقيق منتظمة لضمان استخدام الموارد بفعالية وكفاءة. يمكن لداعمينا ومانحينا أن يثقوا في أن مساهماتهم تحدث تأثيرا كبيرا على أرض الواقع".`,
  },
  {
    id: 6,
    question: 'ما هي المساعدات التي تقدمها MATW في فلسطين؟',
    answer:
      'نحن نقدم حاليا مجموعة من مواد الإغاثة الأساسية في فلسطين، بما في ذلك الطرود الغذائية والوجبات الساخنة والبطانيات والفرش والمستلزمات الطبية. نحن ملتزمون بتلبية الاحتياجات الفورية والماسة للشعب الفلسطيني ".',
  },
  {
    id: 7,
    question: 'هل تعمل MATW حصريا في فلسطين؟',
    answer: `ينصب تركيزنا الأساسي على تقديم المساعدة والدعم الضروريين لشعب فلسطين بسبب الاحتياجات الإنسانية الملحة في المنطقة. لقد خلق الحصار الحالي وضعا حرجا، حيث يكافح الأفراد للوصول إلى الضروريات الأساسية مثل الغذاء والماء والوقود. وعلاوة على ذلك، أجبر الآلاف على اللجوء إلى المدارس والمباني العامة حيث دمرت منازلهم بنيران الجيش. إن قرارنا بالتركيز على فلسطين متجذر في التحديات الحادة والمستمرة التي يواجهها السكان في هذه المنطقة. بالإضافة إلى ذلك ، لدينا وجود راسخ في المنطقة ، مع فرق من ذوي الخبرة الذين يفهمون جغرافية وسياق فلسطين جيدا ، ومرافق مخصصة تم إنشاؤها لتلبية احتياجات الناس بكفاءة. وهذا يسمح لنا بتعظيم تأثيرنا والاستجابة بسرعة للمتطلبات الملحة للمجتمع ".`,
  },
  {
    id: 8,
    question: 'كيف يمكنني المساعدة؟',
    answer:
      'يمكنك المشاركة من خلال التبرع لحملتنا الطارئة في فلسطين وزيادة الوعي بجهودنا. إذا كنت مهتما بالمشاركة بشكل مباشر في جهود التعامل مع المساعدة وتوزيعها ، فيرجى التواصل مع منسق المتطوعين لدينا للحصول على معلومات حول فرص التطوع وكيف يمكنك المساهمة بوقتك ومهاراتك في هذه القضية ".',
  },
  {
    id: 9,
    question: 'كيف يمكنني البقاء على اطلاع وتحديث بأحدث المعلومات حول عملMATW في فلسطين وتأثير تبرعاتي؟',
    answer:
      'للبقاء على اطلاع دائم بجهودنا المستمرة وتأثير مساهماتك ، يمكنك زيارة موقعنا على الإنترنت أو متابعة قنوات التواصل الاجتماعي الخاصة بنا. نحن نشارك بانتظام الأخبار والتقارير والقصص من الميدان لإبقاء مؤيدينا على اطلاع على الوضع والتقدم والتطور في عملنا في فلسطين ".',
  },
];
const palestineSaveOrphansFaqs = [
  {
    id: 0,
    question: 'What is MATW’s impact to date on the ground in Palestine?',
    answer: `Together with our donors, we’ve delivered an impact of more than 30,000,000 since October 7. This has been through the delivery of urgent medical care, food provisions, shelter, essentials and so much more.`,
  },
  {
    id: 1,
    question: 'How is MATW getting relief into Palestine?',
    answer: `Our CEO, Mahmoud Ismail, initiated collaborations with the Jordan Hashemite Charity Organisation, The Egyptian Red Crescent and The Egyptian Youth Council who currently have access to deliver and distribute relief. Our staff on the ground in Palestine receive the relief that is sent in on the trucks or by air and distribute in accordance with our response plans.`,
  },
  {
    id: 2,
    question: 'Is MATW helping Palestinian refugees?',
    answer: `Yes. Our work doesn’t just stop in Palestine, it transcends borders. For those who have left Palestine, we are currently providing monthly stipend provisions to help them start a new life. We’re also finding accommodation and furnishing their homes with furniture, bedding and electrical goods. Click here to learn more about MATW’s Palestine Resettlement Program.`,
  },
];
const palestineSaveOrphansArabicFaqs = [
  {
    id: 0,
    question: 'ما هو تأثير MATWحتى الآن على أرض الواقع في فلسطين؟',
    answer: `جنبا إلى جنب مع الجهات المانحة لدينا، حققنا تأثيرا يزيد عن 30,000,000 منذ 7 أكتوبر. وقد تم ذلك من خلال تقديم الرعاية الطبية العاجلة والمواد الغذائية والمأوى والضروريات وأكثر من ذلك بكثير.`,
  },
  {
    id: 1,
    question: 'كيف تقومMATW بإدخال المساعدات إلى فلسطين؟',
    answer: `بدأ الرئيس التنفيذي للشركة محمود إسماعيل التعاون مع الهيئة الخيرية الأردنية الهاشمية والهلال الأحمر المصري ومجلس الشباب المصري الذين يمكنهم حاليا تقديم المساعدات وتوزيعها. يتلقى موظفونا على الأرض في فلسطين المساعدات التي يتم إرسالها على متن شاحنات أو جوا وتوزيعها وفقا لخطط الاستجابة الخاصة بنا.`,
  },
  {
    id: 2,
    question: 'هل تساعد MATW اللاجئين الفلسطينيين؟',
    answer: `نعم. عملنا لا يتوقف في فلسطين فحسب، بل يتجاوز الحدود. بالنسبة لأولئك الذين غادروا فلسطين، نقدم حاليا رواتب شهرية لمساعدتهم على بدء حياة جديدة. كما أننا نجد أماكن إقامة ونؤثث منازلهم بالأثاث والفراش والسلع الكهربائية. انقر هنا لمعرفة المزيد عن برنامج إعادة توطين فلسطين التابع ل MATW`,
  },
];
const palestineSaveOrphansFrenchFaqs = [
  {
    id: 0,
    question: 'Quel est l’impact de MATW à ce jour sur le terrain en Palestine ?',
    answer: `En collaboration avec nos donateurs, nous avons eu un impact de plus de 30 000 000 depuis le 7 octobre. Cela s’est traduit par la fourniture de soins médicaux d’urgence, de vivres, d’abris, de produits de première nécessité et bien plus encore.`,
  },
  {
    id: 1,
    question: 'Comment MATW achemine-t-il l’aide en Palestine ?',
    answer: `Notre PDG, Mahmoud Ismail, a initié des collaborations avec l’Organisation caritative hachémite de Jordanie, le Croissant-Rouge égyptien et le Conseil égyptien de la jeunesse, qui ont actuellement accès à l’aide et à la distribution. Notre personnel sur le terrain en Palestine reçoit l’aide qui est envoyée par camion ou par avion et la distribue conformément à nos plans d’intervention.`,
  },
  {
    id: 2,
    question: 'Le MATW aide-t-il les réfugiés palestiniens ?',
    answer: `Oui. Notre travail ne s’arrête pas seulement à la Palestine, il transcende les frontières. Pour ceux qui ont quitté la Palestine, nous fournissons actuellement des allocations mensuelles pour les aider à commencer une nouvelle vie. Nous leur trouvons également un logement et aménageons leurs maisons avec des meubles, de la literie et des appareils électriques. Cliquez ici pour en savoir plus sur le programme de réinstallation en Palestine de MATW.`,
  },
];
const orphanSponsorshipFaqs = [
  {
    id: 0,
    question: `What is orphan support?`,
    answer: `Support and care for children who have lost one or both of their parents is referred to as orphan support. Support for orphans may take the form of housing, food, instruction, medical treatment, and emotional support.`,
  },
  {
    id: 1,
    question: `How does AIA - MATW Project ensure the well-being of orphans?`,
    answer: `AIA - MATW Project’s highly qualified staff and volunteers put through countless hours to make sure the orphans get the finest care possible. The orphanage homes are furnished with all the amenities required to ensure the children's welfare and growth.`,
  },
  {
    id: 2,
    question: `How is the money donated to AIA - MATW Project used?`,
    answer: `The money donated to AIA - MATW Project is used to support our various initiatives, including orphanage houses, orphanage maintenance, and support programs for orphans. The organization is committed to ensuring that the funds are used in a transparent and effective manner.`,
  },
  {
    id: 3,
    question: `How can I get involved with AIA - MATW Project?`,
    answer: `You can get involved with AIA - MATW Project by volunteering your time or skills. Volunteers who can assist with a variety of duties, such as fundraising, marketing, and event preparation, are constantly needed by the organization. Through social media and word-of-mouth, you can also spread the word about the company and its initiatives.`,
  },
  {
    id: 4,
    question: `How much does it cost to support an orphan through AIA - MATW Project?`,
    answer: `AIA - MATW Project orphan support fees vary by nation and term of support, as well as other factors. You can visit the organization's website for more information on the cost of each support program.`,
  },
  {
    id: 5,
    question: `How long does it take for my donation to reach an orphan?`,
    answer: `The AIA - MATW Project works to ensure that your donation reaches the orphan as soon as possible. The organization has efficient systems in place to ensure that the funds are transferred to the orphanage houses and used to support the orphans.`,
  },
  {
    id: 6,
    question: `How can I track the progress of the orphan I am supporting through AIA - MATW Project?`,
    answer: `The AIA - MATW Project provides regular updates on the progress of the orphans through their website and social media channels. You can also contact the organization directly for more information on the orphan you are supporting.`,
  },
  {
    id: 7,
    question: `What is an orphanage house?`,
    answer: `An orphanage house is a facility that provides shelter and care for orphans who have lost one or both parents. The orphanage house provides a safe and nurturing environment for the orphans to grow and develop.`,
  },
  {
    id: 8,
    question: `How does AIA - MATW Project ensure the safety and security of the orphans in the orphanage houses?`,
    answer: `AIA - MATW Project takes the safety and security of orphans very seriously. The organization has strict policies and procedures in place to ensure that our orphans are protected from harm. The orphanage houses are equipped with security systems, and the staff and volunteers are carefully screened and trained.`,
  },
  {
    id: 9,
    question: `What other support does AIA - MATW Project offer to orphans?`,
    answer: `The AIA - MATW Project offers a wide range of support to orphans, including healthcare, counseling, and emotional support. The organization also works to reunite orphans with their extended families or place them with suitable caregivers.`,
  },
  {
    id: 10,
    question: `How does AIA - MATW Project ensure the long-term well-being of orphans?`,
    answer: `AIA - MATW Project has a comprehensive approach to ensuring the long-term well-being of orphans. The organization works to provide orphans with the necessary skills and resources to become self-sufficient adults. The organization also works to ensure that the orphans have a safe and stable home environment.`,
  },
];
const palestineFamineFaqs = [
  {
    id: 0,
    question: 'What relief is MATW providing in Palestine?',
    answer: `We are currently providing a range of essential relief in Palestine, including food packs, hot meals, blankets, mattresses, and medical kits. We are committed to address the immediate and critical needs of the people in Palestine.`,
  },
  {
    id: 1,
    question: 'How can I donate, and can I use Zakat for my contributions?',
    answer: 'You can donate through our website and yes, you can use your Zakat donations to support our efforts in Palestine. Zakat is about helping those in need, and providing relief to those in Palestine who have been forcibly displaced is an important cause. At MATW, we ensure that Zakat funds are used for their intended purpose and adhere to Islamic guidelines for Zakat distribution. Your donations will directly benefit those who require assistance in Palestine.',
  },
  {
    id: 2,
    question: 'How do I know my contributions are reaching those in need in Palestine?',
    answer: 'We have a strict system of accountability and transparency. We maintain detailed records of relief distribution and regularly conduct audits to ensure that resources are used effectively and efficiently. Our supporters and donors can trust that their contributions are making a significant impact on the ground.',
  },
  {
    id: 3,
    question: 'What assistance is MATW providing in Palestine?',
    answer: 'We are currently providing a range of essential relief in Palestine, including food packs, hot meals, blankets, mattresses, and medical kits. We are committed to address the immediate and critical needs of the people in Palestine.',
  },
  {
    id: 4,
    question: 'How does MATW adapt to the changing needs in Palestine?',
    answer: 'We are working to continuously monitor the situation in Palestine and the evolving needs of the Palestine population. We work closely with local partners and humanitarian organisations to stay informed about the changing circumstances. This flexibility allows us to adjust our programmes and assistance as needed, ensuring our support remains relevant and effective.',
  },
  {
    id: 5,
    question: 'Is MATW working exclusively in Palestine?',
    answer: `Our primary focus is on providing critical help and support to the people of Palestine due to the pressing humanitarian needs in the region. The current blockade has created a critical situation, where individuals are struggling to access fundamental necessities such as food, water, and fuel. Furthermore, thousands have been forced into sheltering in schools and public buildings as their homes have been destroyed by military fire. Our decision to concentrate on Palestine is rooted in the acute and ongoing challenges faced by the population in this area. Additionally, we have an established presence in the region, with experienced teams who understand the geography and context of Palestine well, and dedicated facilities set up to efficiently address the needs of the people. This allows us to maximize our impact and respond swiftly to the urgent requirements of the community.`,
  },
  {
    id: 6,
    question: 'How can I help?',
    answer: 'You can get involved by donating to our emergency Palestine campaign and raising awareness of our efforts. If you are interested in getting directly involved with our assistance handling and distribution efforts, please reach out to our volunteer coordinator for information on volunteer opportunities and how you can contribute your time and skills to this cause.',
  },
  {
    id: 7,
    question: 'How can I stay informed and up to date with the latest information on MATW’s work in Palestine and the impact of my donations?',
    answer: 'To stay updated on our ongoing efforts and the impact of your contributions, you can visit our website or follow our social media channels. We regularly share news, reports, and stories from the field to keep our supporters informed about the situation and the progress and development of our work in Palestine.',
  },
];
const palestineFamineFaqsArabic = [
  {
    id: 0,
    question: 'ما هي المساعدات التي تقدمها MATW في فلسطين؟',
    answer: `نحن نقدم حاليا مجموعة من المساعدات الأساسية في فلسطين، بما في ذلك الطرود الغذائية والوجبات الساخنة والبطانيات والفرش والمستلزمات الطبية. نحن ملتزمون بتلبية الاحتياجات الفورية والماسة للشعب الفلسطيني.`,
  },
  {
    id: 1,
    question: 'كيف يمكنني التبرع ، وهل يمكنني استخدام الزكاة لمساهماتي؟',
    answer: 'يمكنك التبرع من خلال موقعنا الإلكتروني ونعم ، يمكنك استخدام تبرعاتك الزكوية لدعم جهودنا في فلسطين. الزكاة هي مساعدة المحتاجين، وتقديم المساعدات لأولئك الذين تم تهجيرهم قسرا في فلسطين هو قضية مهمة. في MATW ، نضمن استخدام أموال الزكاة للغرض المقصود منها والالتزام بالمبادئ التوجيهية الإسلامية لتوزيع الزكاة. تبرعاتكم ستفيد بشكل مباشر أولئك الذين يحتاجون إلى المساعدة في فلسطين.',
  },
  {
    id: 2,
    question: 'كيف أعرف أن مساهماتي تصل إلى المحتاجين في فلسطين؟',
    answer: 'لدينا نظام صارم للمساءلة والشفافية. نحتفظ بسجلات مفصلة لتوزيع المساعدات ونجري عمليات تدقيق منتظمة لضمان استخدام الموارد بفعالية وكفاءة. يمكن لداعمينا ومانحينا أن يثقوا في أن مساهماتهم تحدث تأثيرا كبيرا على أرض الواقع".',
  },
  {
    id: 3,
    question: 'ما هي المساعدات التي تقدمها MATW في فلسطين؟',
    answer: 'نحن نقدم حاليا مجموعة من مواد الإغاثة الأساسية في فلسطين، بما في ذلك الطرود الغذائية والوجبات الساخنة والبطانيات والفرش والمستلزمات الطبية. نحن ملتزمون بتلبية الاحتياجات الفورية والماسة للشعب الفلسطيني ".',
  },
  {
    id: 4,
    question: 'كيف تتكيف MATW مع الاحتياجات المتغيرة في فلسطين؟',
    answer: 'نحن نعمل على الرصد المستمر للوضع في فلسطين والاحتياجات المتطورة لسكان فلسطين. نحن نعمل عن كثب مع الشركاء المحليين والمنظمات الإنسانية للبقاء على اطلاع بالظروف المتغيرة. وتسمح لنا هذه المرونة بتعديل برامجنا ومساعداتنا حسب الحاجة، مما يضمن بقاء دعمنا مناسبا وفعالا".',
  },
  {
    id: 5,
    question: 'هل تعمل MATW حصريا في فلسطين؟',
    answer: `ينصب تركيزنا الأساسي على تقديم المساعدة والدعم الضروريين لشعب فلسطين بسبب الاحتياجات الإنسانية الملحة في المنطقة. لقد خلق الحصار الحالي وضعا حرجا، حيث يكافح الأفراد للوصول إلى الضروريات الأساسية مثل الغذاء والماء والوقود. وعلاوة على ذلك، أجبر الآلاف على اللجوء إلى المدارس والمباني العامة حيث دمرت منازلهم بنيران الجيش. إن قرارنا بالتركيز على فلسطين متجذر في التحديات الحادة والمستمرة التي يواجهها السكان في هذه المنطقة. بالإضافة إلى ذلك ، لدينا وجود راسخ في المنطقة ، مع فرق من ذوي الخبرة الذين يفهمون جغرافية وسياق فلسطين جيدا ، ومرافق مخصصة تم إنشاؤها لتلبية احتياجات الناس بكفاءة. وهذا يسمح لنا بتعظيم تأثيرنا والاستجابة بسرعة للمتطلبات الملحة للمجتمع ".`,
  },
  {
    id: 6,
    question: 'كيف يمكنني المساعدة؟',
    answer: 'يمكنك المشاركة من خلال التبرع لحملتنا الطارئة في فلسطين وزيادة الوعي بجهودنا. إذا كنت مهتما بالمشاركة بشكل مباشر في جهود التعامل مع المساعدة وتوزيعها ، فيرجى التواصل مع منسق المتطوعين لدينا للحصول على معلومات حول فرص التطوع وكيف يمكنك المساهمة بوقتك ومهاراتك في هذه القضية ".',
  },
  {
    id: 7,
    question: 'كيف يمكنني البقاء على اطلاع وتحديث بأحدث المعلومات حول عمل MATW في فلسطين وتأثير تبرعاتي؟',
    answer: 'للبقاء على اطلاع دائم بجهودنا المستمرة وتأثير مساهماتك ، يمكنك زيارة موقعنا على الإنترنت أو متابعة قنوات التواصل الاجتماعي الخاصة بنا. نحن نشارك بانتظام الأخبار والتقارير والقصص من الميدان لإبقاء مؤيدينا على اطلاع على الوضع والتقدم والتطور في عملنا في فلسطين ".',
  },
];
const palestineFamineFaqsFrench = [
  {
    id: 0,
    question: 'Quelle aide MATW apporte-t-elle en Palestine ?',
    answer: `Nous fournissons actuellement une gamme d’aide essentielle en Palestine, notamment des colis alimentaires, des repas chauds, des couvertures, des matelas et des kits médicaux. Nous sommes déterminés à répondre aux besoins immédiats et critiques du peuple palestinien.`,
  },
  {
    id: 1,
    question: 'Comment puis-je faire un don et puis-je utiliser la Zakat pour mes contributions ?',
    answer: 'Vous pouvez faire un don via notre site Web et oui, vous pouvez utiliser vos dons de Zakat pour soutenir nos efforts en Palestine. La Zakat a pour but d’aider ceux qui sont dans le besoin, et fournir de l’aide à ceux qui ont été déplacés de force en Palestine est une cause importante. Chez MATW, nous veillons à ce que les fonds de la Zakat soient utilisés aux fins prévues et respectent les directives islamiques pour la distribution de la Zakat. Vos dons bénéficieront directement à ceux qui ont besoin d’aide en Palestine.',
  },
  {
    id: 2,
    question: 'Comment puis-je savoir si mes contributions parviennent à ceux qui en ont besoin en Palestine ?',
    answer: 'Nous avons un système strict de reddition de comptes et de transparence. Nous tenons des registres détaillés de la distribution de l’aide et effectuons régulièrement des audits pour nous assurer que les ressources sont utilisées de manière efficace et efficiente. Nos sympathisants et donateurs peuvent être sûrs que leurs contributions ont un impact significatif sur le terrain.',
  },
  {
    id: 3,
    question: 'Quelle aide le MATW apporte-t-il en Palestine ?',
    answer: 'Nous fournissons actuellement une gamme de secours essentiels en Palestine, notamment des colis alimentaires, des repas chauds, des couvertures, des matelas et des kits médicaux. Nous sommes déterminés à répondre aux besoins immédiats et critiques du peuple palestinien.',
  },
  {
    id: 4,
    question: 'Comment MATW s’adapte-t-il à l’évolution des besoins en Palestine ?',
    answer: 'Nous nous efforçons de suivre en permanence la situation en Palestine et l’évolution des besoins de la population palestinienne. Nous travaillons en étroite collaboration avec des partenaires locaux et des organisations humanitaires pour rester informés de l’évolution de la situation. Cette flexibilité nous permet d’ajuster nos programmes et notre assistance en fonction des besoins, en veillant à ce que notre soutien reste pertinent et efficace.',
  },
  {
    id: 5,
    question: 'Est-ce que MATW travaille exclusivement en Palestine ?',
    answer: `Notre objectif principal est de fournir une aide et un soutien essentiels au peuple de Palestine en raison des besoins humanitaires urgents dans la région. Le blocus actuel a créé une situation critique, où les individus ont du mal à accéder aux produits de première nécessité tels que la nourriture, l’eau et le carburant. En outre, des milliers de personnes ont été contraintes de se réfugier dans des écoles et des bâtiments publics, leurs maisons ayant été détruites par des tirs militaires. Notre décision de nous concentrer sur la Palestine est enracinée dans les défis aigus et permanents auxquels est confrontée la population de cette région. De plus, nous avons une présence établie dans la région, avec des équipes expérimentées qui comprennent bien la géographie et le contexte de la Palestine, et des installations dédiées mises en place pour répondre efficacement aux besoins de la population. Cela nous permet de maximiser notre impact et de répondre rapidement aux besoins urgents de la communauté.`,
  },
  {
    id: 6,
    question: 'Comment puis-je aider ?',
    answer: 'Vous pouvez vous impliquer en faisant un don à notre campagne d’urgence pour la Palestine et en sensibilisant le public à nos efforts. Si vous souhaitez vous impliquer directement dans nos efforts de gestion et de distribution de l’aide, veuillez communiquer avec notre coordonnatrice des bénévoles pour obtenir des renseignements sur les possibilités de bénévolat et sur la façon dont vous pouvez donner de votre temps et de vos compétences à cette cause.',
  },
  {
    id: 7,
    question: 'Comment puis-je rester informé et à jour avec les dernières informations sur le travail de MATW en Palestine et l’impact de mes dons ?',
    answer: 'Pour vous tenir au courant de nos efforts continus et de l’impact de vos contributions, vous pouvez visiter notre site Web ou suivre nos canaux de médias sociaux. Nous partageons régulièrement des nouvelles, des rapports et des histoires sur le terrain pour tenir nos sympathisants informés de la situation et de l’avancement et du développement de notre travail en Palestine.',
  },
];
export {
  palestineFaqs,
  palestineCampaignFaqs,
  palestineFaqsArabic,
  palestineRefugeeFaqs,
  palestineRefugeeFaqsFrench,
  palestineRefugeeFaqsArabic,
  palestineSaveOrphansFaqs,
  orphanSponsorshipFaqs,
  newPalestineFaqsArabic,
  palestineFaqsFrench,
  palestineSaveOrphansArabicFaqs,
  palestineSaveOrphansFrenchFaqs,
  palestineFamineFaqs,
  palestineFamineFaqsArabic,
  palestineFamineFaqsFrench,
  bangladeshEmergency
};
