import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../components/general/layout';
import logoImg from '../../images/landings/usa-general-donation/logo.png';
// import SubscriptionWidget from './subscriptionWidget';
import TabContent from './tab-content';
import services from '../../services';
// import orphanImg from '../../images/landings/subscription/sponsor-img.jpg';
// import orphanMobileImg from '../../images/landings/subscription/sponsor-img-mobile.jpg';
// import legacyImg from '../../images/landings/subscription/legacy-img.jpg';
// import legacyMobileImg from '../../images/landings/subscription/legacy-img-mobile.jpg';
// import mostNeededImg from '../../images/landings/subscription/most-need-img.jpg';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { useSelector } from 'react-redux';
import Support from './support';
const SubscriptionABLandingPage = () => {
  /* ---------------------------------- Variables --------------------------------- */
  // const [orphanProduct, setOrphanProduct] = useState();
  // const [legacyProduct, setLegacyProduct] = useState();
  // const [mostNeededProduct, setMostNeededProduct] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const projectsRef = useRef(null);
  const [hiddenProducts, setHiddenProducts] = useState([0, 0, 0]);
  const [tabs, setTabs] = useState([
    {
      title: 'Orphan Care Fund',
      isActive: true,
      content: (
        <Support
          title="Orphan Care Fund"
          contributes={['Orphan Feast', 'Orphan Care', 'Orphan Centre']}
          isActive={true}
          onClickTab={() => handleActiveTab(0)}
        />
      ),
    },
    {
      title: 'MATW Legacy Fund',
      isActive: false,
      content: (
        <Support
          title="Orphan Care Fund"
          contributes={['Mosque', 'Water Wells', 'Project Noor']}
          isActive={true}
          onClickTab={() => handleActiveTab(1)}
        />
      ),
    },
    {
      title: 'Most Needed Fund',
      isActive: false,
      content: (
        <Support
          title="Orphan Care Fund"
          contributes={['Food & Water Aid', 'Medical Aid', 'Shelter Aid']}
          isActive={true}
          onClickTab={() => handleActiveTab(2)}
        />
      ),
    },
  ]);

  /* ------------------------------ Start Methods ----------------------------- */
  const handleActiveTab = index => {
    const newTabs = tabs.map((obj, inner_index) => {
      obj.isActive = false;
      if (inner_index === index) {
        return {
          ...obj,
          isActive: true,
        };
      }
      return obj;
    });
    setTabs(newTabs);
  };
  const getHiddenProducts = async () => {
    // setIsLoaded(false);
    let tempOrphanPrd = {};
    let tempLegacyPrd = {};
    let tempMostPrd = {};
    let tempHiddenProducts = [];
    let tempTabs = tabs;
    try {
      const response = await services.categoryProductsByName('make a donation');
      response.data.products.map(item => {
        if (Number(item.status) === 1) {
          if (item.creator === 'SSB-ORP-GEN-001') {
            // Orphan Care Fund
            tempOrphanPrd = item;
            tempHiddenProducts.push(item);
          }
          if (item.creator === 'SSB-SDQ-GEN-002') {
            // Legacy Fund
            tempLegacyPrd = item;
            tempHiddenProducts.push(item);
          }
          if (item.creator === 'SSB-WMN-GEN-003') {
            // Most Needed Fund
            tempMostPrd = item;
            tempHiddenProducts.push(item);
          }
        }
        return item;
      });
      tempTabs[0].content = (
        <Support
          title="Orphan Care Fund"
          contributes={[
            {
              title: 'Orphan Feast',
              description: 'Nourish orphans with food and clean water through your donation',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-1-sub-image-1.jpeg',
            },
            {
              title: 'Orphan Care',
              description: 'Help support vulnerable children with all the basic essentials they need to thrive',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-1-sub-image-2.jpeg',
            },
            {
              title: 'Orphan Centre',
              description: 'Invest in the education and growth of orphans so they have a chance to make their  way out of poverty',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-1-sub-image-3.jpeg',
            },
          ]}
          imageUrl={'https://cdn.matwproject.org/images/subscription/support-data-image-1.jpeg'}
          product={tempOrphanPrd}
          description={[
            {
              data: 'Around 5,700 children become orphaned every day.',
              style: 'text-[class="mt-2 text-[#253b7e] font-bold text-[20px]"#253b7e] text-[20px]',
            },
            {
              data: 'Empower and support orphans shattered by adversity to rebuild their lives, giving them hope and a fighting chance at life.',
              style: '',
            },
            {
              data: 'The Prophet SAW said: "I and the one who looks after an orphan shall be in Paradise like these two" — and he raised his index finger and the one next to it, holding them together, barely separate (Bukhari)',
              style: 'font-bold italic xs:text-[20px] md:text-[22px] text-[#14A2DC]',
            },
          ]}
          footerText={'Don’t wait - support an orphan today and transform a life. Click the button below to donate now.'}
          buttonText={'Support An Orphan Now'}
          isActive={true}
          onClickTab={() => handleActiveTab(0)}
          layout={'right'}
        />
      );
      tempTabs[1].content = (
        <Support
          title="MATW Legacy Fund"
          contributes={[
            {
              title: 'Mosque',
              description: 'Rebuild unity in communities through the building and maintenance of masjids',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-2-sub-image-1.jpeg',
            },
            {
              title: 'Water Wells',
              description:
                'Bring thirst relief to parched communities in the poorest parts of the world who have no access to clean, reliable water sources',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-2-sub-image-2.jpeg',
            },
            {
              title: 'Project Noor',
              description: 'Help us restore the eyesight of those with impaired vision with life-changing eye surgery',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-2-sub-image-3.jpeg',
            },
          ]}
          imageUrl={'https://cdn.matwproject.org/images/subscription/support-data-image-2.jpeg'}
          product={tempLegacyPrd}
          description={[
            {
              data: 'Your donations support our ongoing Sadaqah Jariyah projects like Project Noor, food & water aid, mosques and water wells.',
              style: 'text-[class="mt-2 text-[#253b7e] font-bold text-[20px]"#253b7e] text-[20px]',
            },
            {
              data: '"Indeed, those who give charity, men and women, and lend to Allah a goodly loan, it will be multiplied for them, and they will have an honourable reward." (Quran 57:18)',
              style: 'font-bold italic text-[20px] text-[#14A2DC]',
            },
          ]}
          footerText={'Build your legacy today and transform communities for a better tomorrow. Click the button below to donate now.'}
          buttonText={'Build My Legacy Now'}
          isActive={true}
          onClickTab={() => handleActiveTab(1)}
          layout={'right'}
        />
      );
      tempTabs[2].content = (
        <Support
          title="Most Needed Fund"
          contributes={[
            {
              title: 'Food & Water Aid',
              description: 'Nourish hope through food packs, hot meals and clean, safe drinking water',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-3-sub-image-1.jpeg',
            },
            {
              title: 'Medical Aid',
              description: 'Invest in life-saving medical assistance for the health of communities',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-3-sub-image-2.jpeg',
            },
            {
              title: 'Shelter Aid',
              description: 'Keep the vulnerable off the streets and in safe shelters, protecting them from the elements',
              imageUrl: 'https://cdn.matwproject.org/images/subscription/support-data-3-sub-image-3.jpeg',
            },
          ]}
          imageUrl={'https://cdn.matwproject.org/images/subscription/support-data-image-3.jpeg'}
          product={tempMostPrd}
          description={[
            {
              data: 'Make a high-impact donation that addresses critical humanitarian needs as they arise.',
              style: 'text-[class="mt-2 text-[#253b7e] font-bold text-[20px]"#253b7e] text-[20px]',
            },
            {
              data: 'The Most Needed Fund delivers vital medical relief, food, water, shelter, hygiene kits and other resources in disaster zones around the world',
              style: '',
            },
            { data: 'Your donation will support our 2 core programs:', style: '' },
          ]}
          listItems={[
            {
              title: 'Livelihood Programs',
              description:
                'Empower communities to become self-sufficient by breaking the cycle of poverty and transforming the future of generations',
            },
            {
              title: 'Rapid Emergency Response',
              description: 'Help us respond to emergencies immediately with the right resources for greater impact',
            },
          ]}
          footerText={'Invest in both this life and the next through our community relief projects.Click the button below to donate now.'}
          buttonText={'Save Lives Now'}
          isActive={true}
          onClickTab={() => handleActiveTab(2)}
          layout={'right'}
        />
      );
      setHiddenProducts(tempHiddenProducts);
      setTabs(tempTabs);
      // setIsLoaded(true);
    } catch (error) {
      // setIsLoaded(true);
      console.error('error========', error);
    }
  };

  const handleScrollToProducts = () => {
    if (projectsRef.current) {
      const topPos = projectsRef.current.getBoundingClientRect().top + window.scrollY - 100; // 100 pixels offset, adjust as needed
      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
    }
  };

  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */

  useEffect(() => {
    getHiddenProducts('hidden');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout className="bg-white">
      {seo && (
        <Helmet>
          <title>{`${seo['subscription']['title']}`}</title>
          <meta name="title" content={`${seo['subscription']['title']}`} />
        </Helmet>
      )}
      <div className="flex flex-col">
        <div
          className="cursor-pointer order-1 md:order-2 w-full flex flex-wrap bg-[url('../src/images/landings/subscription/subscription-banner.jpg')] items-start lg:bg-right sm:bg-center max-[470px]:bg-top max-[500px]:bg-[-50px]- xl:bg-center bg-cover max-[380px]:min-h-[135px] min-[381px]:min-h-[165px] min-[501px]:min-h-[205px] sm:min-h-[260px] lg:min-h-[400px] xl:min-h-[600px] 2xl:min-h-[700px] px-4 sm:px-4 md:px-0 max-[500px]:py-2 py-3 sm:py-4 md:py-6"
          onClick={handleScrollToProducts}
        ></div>
      </div>
      {/* /* ----------------------------- Tabs --------------------------------------- */}
      <div className="flex flex-wrap justify-center basis-full md:container md:mx-auto md:px-4 py-2 md:py-4" ref={projectsRef}>
        <div className="w-full flex flex-wrap justify-center md:container md:mx-auto md:hidden bg-[#00A3DA] xs:rounded-none md:rounded-t-2xl max-[400px]:px-1- px-3 pt-3 xs:pt-10">
          <div className="w-full grid grid-cols-6 gap-1">
            {tabs.map((item, index) => {
              return (
                <div key={`tabs4_${index}`} className={`col-span-2 flex justify-center items-center`}>
                  <div
                    className={`py-3 max-[500px]:px-2 max-[500px]:text-center max-[500px]:text-xs text-base md:text-lg cursor-pointer w-full flex justify-center items-center ${
                      item.isActive ? 'bg-[#253B7E] text-white rounded-xl' : 'bg-[#253B7E33] text-[#fff] rounded-xl border-[#00a3da]'
                    }`}
                    onClick={() => handleActiveTab(index)}
                  >
                    {item.title}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-center md:container md:mx-auto bg-[#00A3DA] rounded-b-2xl md:rounded-2xl xs:p-0 p-3 sm:p-6 md:p-8">
          <div className="w-full hidden md:grid grid-cols-6 gap-2 pb-4">
            {tabs.map((item, index) => {
              return (
                <div
                  key={`tabs2-${index}`}
                  className={`py-4 text-lg cursor-pointer col-span-2 flex justify-center items-center rounded-2xl ${
                    item.isActive ? 'bg-[#253B7E] text-white rounded-xl' : 'bg-[#253B7E33] text-[#fff] border border-[#00a3da] border-r-0'
                  } ${index === 5 && !item.isActive ? '!border-r' : ''}`}
                  onClick={() => handleActiveTab(index)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <div className="flex w-full">
            {tabs.map((item, index) => {
              if (item.isActive) {
                return <React.Fragment key={`content-${index}`}>{item.content}</React.Fragment>;
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      {/* /* ----------------------------- Text --------------------------------------- */}
      <div className="flex flex-wrap justify-center basis-full md:container md:mx-auto px-4 pb-2 pt-4 md:pt-8 md:pb-4">
        <h2 className="basis-full text-[#253B7E] sm:text-lg md:text-3xl text-center font-bold">
          Create Continual Impact While Building Your Legacy And Your Akhirah Through Regular Giving
        </h2>
        <h2 className="basis-full text-[#14A2DC] sm:text-lg md:text-3xl text-center font-bold italic md:pt-8 md:pb-8">
          The Prophet SAW Said: “ The most beloved to Allah is the one who gives little and often.” (Bukhari)
        </h2>
      </div>
      <div className="bg-[#253B7E] xs:hidden md:block">
        <div className="flex flex-wrap justify-center basis-full md:container md:mx-auto px-4 pb-2 pt-4 md:pt-8 md:pb-8">
          <p className="text-[#FFFFFF] md:text-2xl text-center font-thin">
            Sadaqah jariyah means ongoing or continual charity and is a wonderful way to contribute to the Ummah every month. Our core projects span
            orphan care, legacy projects and community relief:
          </p>
        </div>
      </div>

      {/* /* ------------------------------ Report  Image ------------------------ */}
      <div className="w-full flex flex-wrap justify-center items-center bg-[url('../src/images/landings/subscription/report-img.jpg')] lg:bg-right sm:bg-center max-[470px]:bg-top max-[500px]:bg-[-50px] xl:bg-center bg-cover py-12 md:py-24 px-4 md:px-0 mt-8 md:mt-16">
        <div className="w-full md:container md:mx-auto flex flex-wrap">
          <div className="basis-full md:basis-9/12">
            <h2 className="text-white text-left md:text-left font-['Gotcha'] text-4xl md:text-5xl md:text-[80px] mb-6 md:mb-12">
              Your Regular Impact Report
            </h2>
            <p className="text-white text-left font-thin md:text-lg  md:w-[500px]">
              Every 6 months our donor care team will share vital updates on how your donation has transformed people's lives so you can see the
              impact of your generosity.
            </p>
          </div>
          <div className="flex justify-center items-center py-4 md:py-0 basis-full md:basis-3/12">
            <img src={logoImg} alt="matw" className="w-[100px] h-[100px] md:w-[150px] md:h-[150px]" />
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------------------- Donate Now ------------------------------- */}
      <div className="flex flex-wrap justify-center basis-full md:container md:mx-auto px-4 py-2 md:py-4 mt-8 md:mt-16">
        {/* <p className="text-[#78716C] text-center font-thin text-lg">
                    All donations are 100% tax deductible.
                </p> */}
        <h1 className="w-full text-5xl md:text-5xl text-center text-[#00A3DA] font-['Gotcha']  font-medium mb-1 md:mb-3">
          Ready to change the world with us at <span className="ml-5">MATW?</span>
        </h1>
        <button
          className="text-white flex items-center justify-center mt-4 md:text-4xl transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da]  h-[65px] w-[450px] px-4 sm:px-6  rounded-3xl"
          onClick={() => window.scroll(0, 0)}
        >
          Donate Now!
        </button>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};

export default SubscriptionABLandingPage;