const helpUseReachMoreSection = {
    title: {
        main: {
            en: 'MATW has reached and cared for more than 50,000 orphans worldwide.',
            fr: 'La MATW a fourni de l’aide et des soins à plus de 50 000 orphelins dans le monde.',
            ar: 'وصلت منظمة MATW إلى أكثر من 50،000 يتيم في جميع أنحاء العالم ورعتهم.'
        },
        highlighted: {
            en: 'Help us reach more.',
            fr: 'Aidez-nous à soutenir plus de gens.',
            ar: 'ساعدنا في الوصول إلى المزيد.'
        }

    },
    description: {
        section1: {
            en: 'Every single day, 5,700 children become orphaned. In times of conflict and disaster, children are the first to be affected - especially if they become orphaned. The reality of poverty is a daily burden for adults, imagine what it would be like for a child? Where would they sleep? What would they eat? Who would look after them if they were to fall ill? We have to help them.',
            fr: 'Chaque jour, 5 700 enfants deviennent orphelins. En temps de conflit et de catastrophe, les enfants sont les premiers touchés, surtout s’ils deviennent orphelins. La réalité de la pauvreté est un fardeau quotidien pour les adultes. Imaginez ce que ce serait pour un enfant? Où dormiraient-ils? Que mangeraient-ils? Qui s’occuperait d’eux s’ils tombaient malades? Nous devons les aider.',
            ar: 'كل يوم، يصبح نحو 5,700 طفل يتيماً. في أوقات النزاعات والكوارث، يكون الأطفال هم الأكثر ضعفاً، حيث يصبحون في كثير من الأحيان يتامى وبدون أحد يتدخل لمساعدتهم. تخيلوا معي الصراع اليومي للفقر من وجهة نظر الكبار، ثم تخيلواه من وجهة نظر الطفل. أين سينامون؟ ماذا سيأكلون؟ من سيرعون عنهم إذا مرضوا؟'
        },
        section2: {
            en: 'With no one to turn to and often with no place to go, vulnerable children and orphans need support. This is where we at MATW come in. YOU can help us transform the lives of orphans and vulnerable children all over the world. Your support enables us to provide them with a safe environment to grow, an education to give them hope and options for a brighter future, access to healthcare, nutritious meals and so much more.',
            fr: 'Comme il n’y a personne vers qui se tourner et qu’il n’y a souvent aucun endroit où aller, les enfants et les orphelins vulnérables ont besoin de soutien. C’est là que la MATW intervient. VOUS pouvez nous aider à changer la vie des orphelins et des enfants vulnérables partout dans le monde. Votre soutien nous permet de leur offrir un environnement sûr pour grandir, une éducation qui leur donne de l’espoir et des options pour un avenir meilleur, un accès aux soins de santé, des repas nutritifs et bien plus encore.',
            ar: 'نحن في منظمة MATWنأتي لنقدم الدعم الحيوي لهؤلاء الأطفال الضعفاء والأيتام. دعمكم يمكن أن يغير حياتهم، حيث يتيح لنا توفير بيئة آمنة لنموهم، وتعليم يزرع الأمل ويفتح أفق المستقبل لهم، والوصول إلى الرعاية الصحية، ووجبات غذائية غنية بالعناصر الغذائية، والكثير من الخدمات الأخرى. انضموا إلينا لنصنع فرقاً معنوياً في حياة الأيتام والأطفال الضعفاء في جميع أنحاء العالم.'
        },
        section3: {
            en: 'Help us save and support orphaned children today.',
            fr: 'Aidez-nous à sauver et à soutenir les enfants orphelins dès aujourd’hui.',
            ar: 'ساعدونا في إنقاذ ودعم الأطفال الأيتام اليوم.'
        },
    }
}

const worldProgramSection = {
    title: {
        en: 'MATW’s orphans around the world program.',
        fr: 'Programme des orphelins du monde de la MATW.',
        ar: 'برنامج الأيتام حول العالم لمنظمة MATW.'
    },
    description: {
        section1: {
            en: 'At MATW, we believe that every child deserves access to life’s most basic necessities and a positive start in life. From our humble beginnings with Ali Banat to where we are today, MATW’s Orphan projects have always been a top priority in our plan to alleviate suffering around the world.',
            fr: 'Chez MATW, nous croyons que chaque enfant mérite d’avoir accès aux nécessités les plus élémentaires de la vie et de prendre un bon départ dans la vie. Depuis nos humbles débuts avec Ali Banat jusqu’à aujourd’hui, les projets destinés aux orphelins de la MATW ont toujours été une priorité absolue de notre plan visant à soulager la souffrance dans le monde entier.',
            ar: 'في منظمة MATW، نؤمن بأن كل طفل يستحق الوصول إلى أبسط حقوق الحياة وبداية إيجابية في الحياة. من بداياتنا المتواضعة مع علي بنات وحتى اليوم، كانت مشاريع الأيتام فيMATW دائماً من أولوياتنا الرئيسية في خطتنا لتخفيف معاناة الناس في جميع أنحاء العالم.'
        },
        section2: {
            en: 'We started in Togo by supporting one orphan and now we operate entire centres in Togo and Lebanon, home to more than 5,000 orphaned, children and their widowed mothers.',
            fr: 'Nous avons commencé au Togo en soutenant un orphelin et nous dirigeons maintenant des centres entiers au Togo et au Liban, où vivent plus de 5 000 enfants orphelins et leurs mères veuves.',
            ar: 'بدأنا في توغو بدعم يتيم واحد والآن ندير مراكز كاملة في توغو ولبنان، تعتبر موطناً لأكثر من 5,000 يتيم وأمهاتهن الأرامل.'
        },
        section3: {
            en: 'We continue to provide support to orphans in seven countries including Togo, Lebanon, Palestine, Yemen, Syria, Bangladesh and Jordan.',
            fr: 'Nous continuons à soutenir les orphelins dans sept pays : le Togo, le Liban, la Palestine, le Yémen, la Syrie, le Bangladesh et la Jordanie.',
            ar: 'نحن مستمرون في تقديم الدعم للأيتام في سبع دول، بما في ذلك توغو، لبنان، فلسطين، اليمن، سوريا، بنغلاديش، والأردن.'
        },
    },
    list: {
        title: {
            top: {
                en: 'We work toward their prolonged wellbeing.',
                fr: 'Nous travaillons à leur bien-être à long terme.',
                ar: 'حن نعمل نحو رفاهية مطولة لهم.'
            },
            bottom: {
                en: 'Our support includes:',
                fr: 'Notre soutien comprend :',
                ar: 'دعمنا يشمل:'
            }
        },
        listItems: [
            {
                en: 'A nurturing environment.',
                fr: 'Un environnement stimulant.',
                ar: 'بيئة محيطة رعوية.',
            },
            {
                en: 'Access to formal schooling.',
                fr: 'Accès à la scolarité formelle.',
                ar: 'وصول إلى التعليم الرسمي.',
            },
            {
                en: 'Recreational activities.',
                fr: 'Activités récréatives.',
                ar: 'أنشطة ترفيهية.',
            },
            {
                en: 'Islamic education, including learning of the Quran.',
                fr: 'L’éducation islamique, y compris l’apprentissage du Coran.',
                ar: 'التعليم الإسلامي، بما في ذلك تعلم القرآن.',
            },
            {
                en: 'Regular health checkups and medical attention.',
                fr: 'Examens médicaux réguliers et soins médicaux.',
                ar: 'فحوصات صحية منتظمة ورعاية طبية.',
            },
            {
                en: 'Nutritious meals.',
                fr: 'Repas nutritifs.',
                ar: 'وجبات غذائية مغذية.',
            },
            {
                en: 'Basic necessities including clothing.',
                fr: 'Produits de première nécessité, y compris les vêtements.',
                ar: 'الضروريات الأساسية بما في ذلك الملابس.',
            },
        ]
    }
}

export { helpUseReachMoreSection, worldProgramSection };